<!--  -->
<template>
  <layout>
    <div class="search-top">
      <div class="contant">
        <div class="contant-top">
          <div class="contant-logo">
            <img src="@/assets/home/logo.png" alt @click="$router.push('/')" />
            <span>爱玩就上爱租号</span>
          </div>
          <div class="contant-search">
            <input type="text" placeholder="搜你想要的~" v-model="valueList"/>
            <button @click="goSerach">搜索</button>
          </div>
        </div>
        <div class="contant-bottom">
          <div class="contant-hot">
            <img src="@/assets/home/hot.png" alt="" />
            <span>热门游戏</span>
            <div class="newClass">
              <hotlist></hotlist>
            </div>
          </div>
          <ul>
            <li :class="{ active: isShow == 0 }" @click="toClick(0)">首页</li>
            <li :class="{ active: isShow == 1 }" @click="toClick(1)">爱租号</li>
            <li :class="{ active: isShow == 2 }" @click="toClick(2)">
              工具下载
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bj" >
      <div class="protocol">
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <h3
          style="
            font-size: 16pt;
            line-height: 172%;
            margin: 13pt 0pt;
            orphans: 0;
            page-break-after: avoid;
            page-break-inside: avoid;
            text-align: center;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 16pt; font-weight: bold"
            >爱租号</span
          ><span style="font-family: 宋体; font-size: 16pt; font-weight: bold"
            >仲裁规则</span
          >
        </h3>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >此仲裁规则为客服人员处理订单的参考标准，具体处理方式将会根据租用记录和撤单率，以及订单实际情况由客服人员人性化的进行判断处理，并非完全依据仲裁规则处理，如遇到客服仲裁结果与仲裁规则有差异的情况请号主和租客双方理解，有异议的情况下可以联系在线客服进行咨询沟通解决。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">新版仲裁规则</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc20374"
            ><span style="font-family: Calibri; font-size: 10.5pt">1.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >密码错误</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >密码错误：客服会尽快核实，如若核实租方在租用账号登陆后确认密码错误，请直接进行投诉。投诉原因为：密码错误，客服会进行仲裁此订单。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）游戏时长在订单时长一半以内，返还全部租金；</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）超过一半，由于租客已游戏，但游戏体验受影响，将扣除一半租金给与号主。确定密码错误，扣除赔付补偿租客（守望先锋，腾讯类端游，明文账号密码特殊性原因暂不扣除赔付）</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >客服核实后如发现密码正确无误，说明租客登录方式或者流程出现错误，</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）可咨询在线客服咨询协助</span
          ><span style="font-family: Calibri; font-size: 10.5pt">.15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟）内也会返还租金</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt">）超过</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟）则按时收取租金，有游戏记录会按照不想玩儿处理，多扣除一小时租金（有游戏记录说明密码正确登录成功）</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc17079"
            ><span style="font-family: Calibri; font-size: 10.5pt">2.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >无法登陆（非密码错误问题）</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >订单投诉后，后台处理客服会尽快核实</span
          ><span style="font-family: Calibri; font-size: 10.5pt">.</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >无游戏记录会返还租方的租金。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >用户如果是登陆过程中出现一些上号器提示请进行截图然后联系在线客服并进行投诉，请详细留言上号器所显示的提示，后台处理客服核实后会进行留言处理</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt">）无游戏记录</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟）内还返全部租金</span
          ><span style="font-family: Calibri; font-size: 10.5pt">;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）无游戏记录且超过</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟）的，会按时扣除部分租金；</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）有游戏记录且超过</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟）按照不想玩儿处理；</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">4</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）新手用户或因异常无法正常游戏者，可酌情处理，</span
          ><span style="font-family: Calibri; font-size: 10.5pt">20</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟之内返还全部租金。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc13418"
            ><span style="font-family: Calibri; font-size: 10.5pt">3.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >游戏账号冻结</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">上号器登陆：</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）如果是游戏账号冻结，无登录记录会返还租金；</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）有登录记录并且超过</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟）会按时收取部分租金（因为冻结是由于异地登录频繁导致的）。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">明文登陆：</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）明文存在自动改密的，确认用户是否已重新租用，如未重新租用，更改密码之后取消投诉；</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）如已经重新租用其他账号，可给与撤单之后更改密码。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc24702"
            ><span style="font-family: Calibri; font-size: 10.5pt">4.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >描述不符</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >如租客在租用期间，租用到的账号描述不符请在</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟）内投诉订单，切勿进行游戏后在进行投诉，投诉时请务必说明清楚具体不符道具或者装备（以号主描述为主），方便客服核实。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）如果核实存在较大的差别，</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟）内还返全部租金；</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）超过十五分钟，无游戏记录，按时间扣除租金；</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt">）超过</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟）且有游戏记录将按不想玩儿处理，多扣除一小时租金。同时下架此帐号。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">4</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）如果租客所说的不符并不是号主描述缺少的物品或人物又或者不描述清楚哪里不符的，客服将取消投诉；</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">5</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）如多次重复投诉且未描述清楚哪里不符的，将按照不想玩儿处理（多扣除一小时租金）。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc29033"
            ><span style="font-family: Calibri; font-size: 10.5pt">5.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >租错号或租错时间了</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >如租客在租用期间，租错号或租错时间了，请及时投诉，切勿进行游戏后在进行投诉</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）上号器登录，投诉原因为：租错号或租错时间了，</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">钟内（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟）退全款，超时将按不想玩儿处理，多扣除一小时租金，连续</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >单租错无故撤单者将视为恶意投诉并且扣除租金；</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）明文密码订单：明文账号密码租错系统（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">IOS/</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >安卓）或因其他问题导致租用错误导致租客无法使用此账号游戏，考虑此现象并不是号主责任，客服核实后会收取一半租金作为对号主的补偿，然后进行撤单。（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >、扣除租金不得低于</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt">元。</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt">、租用时长</span
          ><span style="font-family: Calibri; font-size: 10.5pt">6</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >小时及以上订单扣除</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt">小时租金，</span
          ><span style="font-family: Calibri; font-size: 10.5pt">6</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >小时以下订单扣除一半租金，如游戏时间超过</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟）扣除当前游戏时间后按照以上规则进行处理）</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc29993"
            ><span style="font-family: Calibri; font-size: 10.5pt">6.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >不想玩了或其它理由不玩了</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >租方在游戏期间因个人原因不想玩了或其它理由无法进行游戏，投诉原因为：不想玩了或其他理由；</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）不想玩是会多扣除</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >小时租金，超过</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟）会按照</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt">小时计算。</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >例如：租用订单</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt">小时，游戏</span
          ><span style="font-family: Calibri; font-size: 10.5pt">20</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟直接扣除全部租金，租用</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt">小时，游戏</span
          ><span style="font-family: Calibri; font-size: 10.5pt">20</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟，将返还一个小时租金</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）如租客订单未超过十五分钟，将返还全部租金，并提醒租客下次合理安排游戏时间。明文账号密码游戏不想玩与租错了处理方式相同（明文账号密码租错系统（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">IOS/</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >安卓）或因其他问题导致租用错误导致租客无法使用此账号游戏，考虑此现象并不是号主责任，客服核实后会收取一半租金作为对号主的补偿，然后进行撤单。（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >、扣除租金不得低于</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt">元。</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt">、租用时长</span
          ><span style="font-family: Calibri; font-size: 10.5pt">6</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >小时及以上订单扣除</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt">小时租金，</span
          ><span style="font-family: Calibri; font-size: 10.5pt">6</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >小时以下订单扣除一半租金，如游戏时间超过</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟扣除当前游戏时间后按照以上规则进行处理））</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc9363"
            ><span style="font-family: Calibri; font-size: 10.5pt">7.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >号主投诉违规</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >号主投诉租方违规，如客服核实确实违规，扣除全部租金和押金；</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）端游：如号主提供证据不足，将返还全部租金（端游投诉会影响租客游戏体验）；</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）手游：如号主提供证据不足，取消投诉或返还全部租金（不影响租客游戏体验）；</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）如账号冻结，请先投诉订单后。在更新密码以免造成客服核实存在误差，按照密码错误类型处理。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc3012"
            ><span style="font-family: Calibri; font-size: 10.5pt">8.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >第三方检测</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）开启外挂等第三方风险软件（鼠标宏，压枪软件，自定义皮肤）等一些修改游戏数据软件，是会直接扣除租金，电脑存在外挂也算试图使用（不准备使用您下载干什么呢）请各位用户使用绿色游戏，严重者我们会采取法律措施。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）如检测用户电脑存在第三方外挂残留，或异常文件，建议用户家里重新安装系统（全盘），如在网吧建议用户更换电脑。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc17809"
            ><span style="font-family: Calibri; font-size: 10.5pt">9.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >环境异常</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）此类型投诉是上号器检测到租客电脑问题，系统自动发起的投诉。有些是租客电脑确实是存在外挂软件或者一些辅助软件的残留的。但是也是有些租客电脑自身的问题。客服会根据投诉内容，和上号器获取的一些信息进行判断的。如果确实电脑存在外挂软件。会扣除全部租金。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）如果只是租客电脑的问题。会返还全部租金的。请各位号主悉知。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >（如订单超时将按时扣除租金）</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc6909"
            ><span style="font-family: Calibri; font-size: 10.5pt">10.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >申诉订单</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >租客和号主如果对订单处理的不满意可以对此订单发起申诉，申诉时请务必上传有效的证明截图，如无法上传有效截图，客服这边无法进行核对，会按照申诉失败处理。如因证据不足导致失败，请注意查看客服留言查询所需证明，重新提交申诉</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc24436"
            ><span style="font-family: Calibri; font-size: 10.5pt">11.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >明文密码投诉</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >由于手游上号方式问题，订单投诉处理较为特殊，如用户投诉：</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）租错号了：明文账号密码租错系统（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">IOS/</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >安卓）或因其他问题导致租用错误导致租客无法使用此账号游戏，考虑此现象并不是号主责任，客服核实后会收取一半租金作为对号主的补偿，然后进行撤单（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >、扣除租金不得低于</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt">元。</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt">、租用时长</span
          ><span style="font-family: Calibri; font-size: 10.5pt">6</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >小时及以上订单扣除</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt">小时租金，</span
          ><span style="font-family: Calibri; font-size: 10.5pt">6</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >小时以下订单扣除一半租金，如游戏时间超过</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟扣除当前游戏时间后按照以上规则进行处理）</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）账号不符：如租客描述不符，客服核实账号不符给与撤单，核实账号符合，取消投诉；如租客未描述，留言说明请租客具体描述，给与取消投诉</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）被挤号了：由于手游无法判定是否存在挤号，如用户投诉被挤号了，客服将联系双方协助解决，未联系到或联系后未更改，返还全部租金；时间过长的，按照游戏时长，少扣除一半租金，给与撤单</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">4</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）密码错误，无法登陆：如用户投诉密码错误或无法登陆，客服会核实账号是否能够正常登陆，如账号无法正常登陆，将返回全部租金；如该账号可以正常登陆，客服将取消投诉（特殊情况联系双方协商）</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">5</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）由于一些账号的特殊机制，类似于球球大作战</span
          ><span style="font-family: Calibri; font-size: 10.5pt">IOS</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >版以及英雄互娱账号，九游，玲珑加速器等，只需要使用当前的密码就可以更改新密码的账号，如投诉挤号或者密码错误一类，且号主在上一单结束之后有更新新密码，投诉之后客服这边会及时联系号主重新更新新密码</span
          ><span style="font-family: Calibri; font-size: 10.5pt">5</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟内号主会更新新密码同步在网站，租客需要刷新一下即可获得最新密码进行登录。联系不到号主或者号主超过时间未修改新密码会给与撤单处理。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">注：订单投诉</span
          ><span style="font-family: Calibri; font-size: 10.5pt">,</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >撤单后请您及时下号，请勿继续游戏，如继续进行游戏将按照默认账号无问题，扣除订单全部租金。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc7243"
            ><span style="font-family: Calibri; font-size: 10.5pt">12.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >禁言，禁赛（扣信誉分），裁决</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）租方在登录账号后如发现账号有被禁赛（扣信誉分）现象，可直接进行投诉。客服会核实账号是否是租方导致所致，如是租方导致会扣除全部租金及保证金，如不是租方导致会返还全部租金并下架此账号。（时间较长，按时间扣除租金）</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）租方在登录账号后如发现账号有被发广告诈骗（禁言）现象，可直接进行投诉。客服会核实账号是否是租方导致所致，如租方导致，扣除全部租金及保证金，不是租方导致会返回全部租金并下架此账号（时间较长，按时间扣除租金）</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）如果王者荣耀租客未确认游戏导致扣除一分或两分的情况，考虑到租客的无心，如果没有影响到账号的可用性，则不算做违规，如果因为未确认游戏导致账号无法使用，则视为违规。注：连续多次未确认游戏扣除信誉分也算作违规。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc32189"
            ><span style="font-family: Calibri; font-size: 10.5pt">13.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >号被封了</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）租方登录账号发现账号已经被封禁，如非租方原因造成，会返还全部租金并给与审核不通过货架，号主需要更换账号才能重新上架。（如核实封号是在租客租用期间并且租客存在登录记录将扣除全部租金）</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）如果经客服核实账号没有被封，</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟）内返还租金并记录一次撤单记录。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt">）超过</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt">分钟（手游为</span
          ><span style="font-family: Calibri; font-size: 10.5pt">10</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >分钟）则会按时收取租金，有游戏记录则按不想玩处理。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >注：冻结，禁赛，禁言等不属于封号，请选择其他类型投诉。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >（明文账号密码核实未被封号，将警告租客一次，取消投诉）</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc11636"
            ><span style="font-family: Calibri; font-size: 10.5pt">14.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >逃跑挂机租客违规</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >租方在租用期间有违规操作：逃跑挂机，违规排位，发广告导致账号禁言，扣信誉积分导致账号被封等损害号主账号情况，客服核实后一律扣除全部租金和押金</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc3672"
            ><span style="font-family: Calibri; font-size: 10.5pt">15.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >客服仲裁错误</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >如遇客服仲裁失误，请联</span
          ><span style="font-family: 宋体; font-size: 10.5pt">系</span
          ><span style="font-family: 宋体; font-size: 10.5pt">微信</span
          ><span style="font-family: Calibri; font-size: 10.5pt">: </span
          ><span
            style="
              background-color: #ffffff;
              color: #111f2c;
              font-family: 微软雅黑;
              font-size: 10.5pt;
              font-style: normal;
              text-transform: none;
            "
            >wxid_8y7dudye7e8l22</span
          ><span style="font-family: 宋体; font-size: 10.5pt">上班时间，</span
          ><span style="font-family: Calibri; font-size: 10.5pt">24</span
          ><span style="font-family: 宋体; font-size: 10.5pt">小时在线</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc5892"
            ><span style="font-family: Calibri; font-size: 10.5pt">16.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >审核问题</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >账号审核时间为</span
          ><span style="font-family: Calibri; font-size: 10.5pt"
            >9:00-1:00 3</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >小时一次，开启赔付功能无需审核直接上架！（不包含王者荣耀）</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc18600"
            ><span style="font-family: Calibri; font-size: 10.5pt">17.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >违规货架</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">1</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）为保证账号质量，客服将对所上架的账号进行审查，发现账号描述不符合，留有联系方式，违规货架，</span
          ><span style="font-family: 宋体; font-size: 10.5pt">爱租号</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >有权对该货架进行限制，限制时间如下：第一次</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt">天。第二次</span
          ><span style="font-family: Calibri; font-size: 10.5pt">7</span
          ><span style="font-family: 宋体; font-size: 10.5pt">天。第三次</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >天。第四次删除。希望各位号主珍惜自己的货架！</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">2</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）如发布违规货架，在审核不通过警告无效的情况下，客服会关闭其发布权限进行处理！</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt">（</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >）如有其它特殊订单问题可以联系</span
          ><span style="font-family: 宋体; font-size: 10.5pt">爱租号</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >人工在线客服满足您的需求。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc10162"
            ><span style="font-family: Calibri; font-size: 10.5pt">18.</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >账号问题撤单过多</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >客服仲裁期间如发现号主因账号问题撤单占比高于平台撤单占比，第一次限制</span
          ><span style="font-family: Calibri; font-size: 10.5pt">7</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >天上架，第二次删除货架。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">19</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >丶为保证账号质量，客服将对所上架的账号进行审查，发现账号描述不符合，留有联系方式，违规货架，</span
          ><span style="font-family: 宋体; font-size: 10.5pt">爱租号</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >有权对该货架进行限制，限制时间如下：第一次</span
          ><span style="font-family: Calibri; font-size: 10.5pt">3</span
          ><span style="font-family: 宋体; font-size: 10.5pt">天。第二次</span
          ><span style="font-family: Calibri; font-size: 10.5pt">7</span
          ><span style="font-family: 宋体; font-size: 10.5pt">天。第三次</span
          ><span style="font-family: Calibri; font-size: 10.5pt">15</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >天。第四次删除。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >希望各位号主珍惜自己的货架！</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc27108"
            ><span style="font-family: Calibri; font-size: 10.5pt">20</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >、自己的号要撤单</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >由于用户在租用期间被撤单，严重影响租客游戏体验，如号主要求撤单，将返还租客全部租金并扣除错误赔付补偿用户，请号主在账号租用期间，不要随意撤单。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <a name="_Toc5069"
            ><span style="font-family: Calibri; font-size: 10.5pt">21</span
            ><span style="font-family: 宋体; font-size: 10.5pt"
              >、被挤号了（上号器登录）</span
            ></a
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >端游挤号，如核实存在挤号，返还全部租金，如核实不存在挤号，将按照不想玩儿处理，多扣除一小时租金给与撤单，并记录租客撤单率</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >投诉撤单情况下，退还金额优先退还红包，扣除金额优先扣除余额。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: 宋体; font-size: 10.5pt"
            >如有其它特殊订单问题可以联系</span
          ><span style="font-family: 宋体; font-size: 10.5pt">爱租号</span
          ><span style="font-family: 宋体; font-size: 10.5pt"
            >人工在线客服满足您的需求。</span
          >
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 10.5pt;
            line-height: 115%;
            margin: 0pt 0pt 10pt;
            orphans: 0;
            text-align: justify;
            widows: 0;
          "
        >
          <span style="font-family: Calibri; font-size: 10.5pt">&nbsp;</span>
        </p>
      </div>
    </div>
  </layout>
</template>
 
<script>
import layout from "../../components/layout";
import hotlist from '../../components/hotlist'

export default {
  name: "protocol",
  data() {
    return {
      isShow: 1,
      valueList: ''
    };
  },
  components: {
    layout,
    hotlist
  },
  methods: {
    toClick(id) {
      if (id == 0) {
        this.$router.push({
          path: "/home",
        });
      } else if (id == 2) {
        this.$router.push({
          path: "/download",
        });
      } else {
        this.$router.push({
          path: "/lobby",
        });
      }
      this.isShow = id;
    },
      goSerach() {
        if(this.valueList){
            this.$router.push({
            path: '/lobby',
            query: {
            key_word: this.valueList
            }
          })
        }
      
    },
  },
};
</script>
 
<style lang='scss' scoped >
.search-top {
  width: 100%;
  background-color: #fff;
  height: 168px;
  .contant {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .contant-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 40px;
      height: 86px;
      .contant-logo {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 218px;
          height: 55px;
          margin-right: 18px;
        }
        span {
          font-size: 15px;
        }
      }
      .contant-search {
        width: 480px;
        height: 48px;
        background: #ffffff;
        border: 2px solid #3c7efe;
        border-right: none;
        border-radius: 24px;
        box-sizing: border-box;
        display: flex;

        input {
          width: 100%;
          height: 100%;
          border: none;
          outline-style: none;
          border-radius: 24px;
          text-indent: 2em;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a2a6b8;
        }
        button {
          position: relative;
          width: 120px;
          height: 48px;
          background: #3c7efe;
          border-radius: 24px;
          color: #ffffff;
          font-size: 18px;
          box-sizing: border-box;
          margin-top: -2px;
          padding-left: 20px;
          cursor: pointer;
        }
        button::after {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../../assets/home/ss.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
    .contant-bottom {
      display: flex;
      .contant-hot {
        width: 200px;
        height: 42px;
        border-radius: 12px 12px 0 0;
        background-color: #3c7efe;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
          position: relative;
          cursor: pointer;
        &:hover .newClass{
          max-height: 460px;
          transition: max-height ease-in .3s;
        }
        img {
          width: 16px;
          height: 18px;
          margin-right: 12px;
        }
        span {
          font-size: 16px;
          color: #ffffff;
        }
        .newClass{
          position: absolute;
          top: 42px;
          left: 0;
          max-height: 0;
          min-height: 0;
          overflow: hidden;
          transition: max-height ease-out .4s;
        }
      }
      ul {
        display: flex;
        align-items: center;
        li {
          font-size: 14px;
          color: #666;
          padding: 0 30px;
          // height: 100%;
          line-height: 17px;
          position: relative;
          cursor: pointer;
        }
         .active{
                   color: #000;
                   font-weight: bold;
                 }
        .active:after {
          position: absolute;
          bottom: -9px;
          left: 50%;
          margin-left: -7px;
          display: block;
          content: "";
          width: 14px;
          height: 3px;
          background-color: #3b7dfd;
        }
      }
    }
  }
}
//
.protocol {
  width: 1000px;
  margin: 0 auto;
  
}
.bj{
    width: 100%;
    padding: 90px 0 60px;
    background-color: #f6f7fa;
}
</style>